.App {
    --s1: 24px;
    --s2: 20px;
    --s3: 14px;
    --s4: 12px;
    --s5: 12px;
    --s6: 12px;

    --w1: 800;
    --w2: 700;
    --w3: 500;

    --p1: 48px;
    --p2: 24px;
    --p3: 20px;
    --p4: 16px;
    --p5: 8px;
    --p6: 32px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width: 1184px) {
    .App {
	--s3: 16px;
    }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .main-bg { */
/*     width: 100vw; */
/*     height: 100vh; */
/*     position: absolute; */
/*     top: 0; */
/*     left: 0; */
/*     background-color: transparent; */
/*     background: url("background.jpg") no-repeat center; */
/*     z-index: -1; */
/*     background-attachment: fixed; */
/* } */

body {
    background-color: transparent;
    background: url("background.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
