.btn-klaytn-wallet {
    width: 232px;
    font-size: 16px;
    z-index: 1;
    background: #fff;
    line-height: 140%;
    font-weight: var(--w2);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 16px;
}

.btn-select-wallet {
    padding: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    border-radius: 16px;
    border: 1px solid #DBDBDB;
    box-sizing: border-box;
}

.btn-select-wallet .ic-kaikas {
    position: absolute;
    left: 35%;
}

.btn-select-wallet .ic-metamask {
    position: absolute;
    left: 30%;
}

.modal-wallets {
    font-size: 16px;
    font-weight: 700;    
}

.modal-wallets ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.modal-wallets li a {
    display: block;
    color: #000000;
    text-decoration: none;
    padding-left:15px;
}

.modal-wallets .modal-header {
    width: 350px;
    display: block;
    text-align: center;
}

.modal-wallets .modal-header h3 {
    font-size: 20px;
    font-weight: 700;
}

.modal-wallets .modal-header p {
    font-size: 14px;
    font-weight: 500;
}

.modal-wallets p {
    display: block;
    color: rgb(112, 122, 131);
    margin: 0;
}

.ic-kaikas {
   background: url("ic-kaikas.webp") left top;
   width: 24px;
   height: 24px;
   margin-top: 0px;
   float: left;
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
   margin-right: 12px;
}

.ic-metamask {
   background: url("ic-metamask.webp") left top;
   width: 24px;
   height: 24px;
   margin-top: 0px;
   float: left;
   background-size: contain;
   background-repeat: no-repeat;
   background-position: center;
   margin-right: 12px;
}
