.main-card {
    display: table;
    margin: 30px auto 10px;
    width: 484px;
    background: inherit;
    background: rgba(255, 255, 255, 0.76);
    backdrop-filter: blur(40px);
    border-radius: 24px;
    padding-top: var(--p1);
    padding-left: var(--p1);
    padding-right: var(--p1);
    font-size: var(--s2);
    font-weight: bold;
}

@media (max-width: 484px) {
    .main-card {
	width: 100%;
	padding-top: var(--p6);
	padding-left: var(--p3);
	padding-right: var(--p3);
    }
    .container-md {
	padding: 0;
    }
}

.main-card h1 {
    font-size: var(--s1);
    font-weight: var(--w1);
    margin-bottom: 4px;
}

.main-card h3 {
    font-size: var(--s3);
    font-weight: var(--w2);
    margin-bottom: 16px;
}

.main-card h2 {
    font-size: var(--s2);
    font-weight: var(--w2);
    padding: 0;
    margin: 0;
}

.main-card h4 {
    font-size: var(--s4);
    font-weight: var(--w3);
    margin-bottom: 4px;
}

.main-card .row {
    padding-top: var(--p3);
    padding-bottom: var(--p3);
}

.main-card .block-title {
    display: inline-block;
    width: 50%;
    line-height: 140%;
    color: #000;
    size: 14px;
}

.main-card .block-title span {
    color: #888;
    size: 10px;
}


.main-card .block-content {
    display: inline-block;
    vertical-align:top;
}

.main-card .block-content.current {
    color: #3A86FF;
}

.main-card .block-line {

}

.main-card hr {
    margin: 0;
}

.box-title {
    display: inline-block;
    width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.box-content {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.logo.navbar-brand {
    width: 88px;
    height: 51px;
    background: url("logo.png") left top;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.ic-klaytn {
    background: url("klaytn.png") left top;
    width: var(--s2);
    height: var(--s2);
    margin-top: 0px;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: var(--s5);
}

.main-card .ic-klaytn {
    margin-top: 1px;
}

.other-info {
    font-size: 14px;
    color: #000;
    text-align: center;
}

.other-info a {
    color: #000;
}

.container-captcha {
    margin-top: 20px;
    margin-bottom: 20px;
}
.container-captcha div {
    margin: auto;
}

.no-kaikas {
    text-align: center;
    padding: 60px;
}

.toasts-container {
    /* display: block; */
    /* width: 100px; */
    /* height: 50px; */
}

.toasts-container .toast-success {
    display: inline-block;
    font-size: 25px;
    /*     /\* width: 100px !important; *\/ */
    /*     /\* height: 50px !important; *\/ */
}

.toasts-container .toast-error {
    display: inline-block;
    font-size: 25px;
    /*     /\* width: 100px !important; *\/ */
    /*     /\* height: 50px !important; *\/ */
}

.freemint-card {
    text-align: center;
}

.freemint-card .title-img {
    width: 50%;
}
.freemint-card .subtitle-img {
    width: 60%;
    margin-top: 12px;
}

.freemint-card .nft-info {
    margin-top: 40px;
    margin-down: 40px;
}

.remaining-nfts {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    text-align: center;
    text-transform: uppercase;

    color: #000000;

    opacity: 0.7;
}

.number-card {
    padding: 10px 25px;
    margin: 4px;
    background: #22272E;
    border-radius: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 120%;
    leading-trim: both;
    text-edge: cap;
    text-align: center;
    letter-spacing: 0.25px;

    color: #F1F9FF;
}

.daily-sales {
    margin-top: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;

    color: #999999;
}
.info-line {
    margin: 40px 0px;
}
.info-line p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.7;
    margin-bottom: 0px;
}

.info-line h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.25px;
    color: #000000;
}

.mint-line {
    margin-bottom: 40px;
}

.other-info.freemint {
    margin: 10px auto 10px;
    width: 484px;
    color: white;
    text-align: left;
}
