.react-numeric-input {
    font-size: var(--s1);
    font-weight: var(--w1);
    margin-top: 36px;
    margin-bottom: var(--p4);
}

.react-numeric-input b {
    width: 40px !important;
    height: 40px;

    background: #1B1B1B !important;
    border-radius: 8px;
}

.react-numeric-input i {
    background: #fff !important;
}

.react-numeric-input input {
    background: none;
    border: none;
    font-weight: var(--w1);
}

.btn-mint {
    width: 100%;
    background: linear-gradient(94.25deg, #FF006E 0%, #3A86FF 100%);
    border-radius: 16px;
    font-size: var(--s1);
    font-weight: var(--w1);
    height: 54px;
    margin-bottom: var(--p5);
}

.btn-mint.ready {
    background: #B6BEDB;
    border: 0;
}

.mint-title {
    position: relative;
}

.mint-title h3 {
    position: absolute;
    left: 0;
}

.mint-desc {
    position: absolute;
    right: 0;
    margin-top: 2px;
    margin-bottom: 0px;
    height: var(--s3);
}

.line-balance {
    text-align: center;
    font-size: var(--s4)
}

.line-balance span {
    color: #555555;
    font-weight: var(--w2);
}

.circle-progress {
    position: absolute;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, 10%);
    text-align: center;
    z-index: 10;
}

h3 span {
    color: #888;
    font-size: 12px;
    font-weight: var(--w3);
}

.content.size-small {
    color: #888;
    font-size: 12px;
    font-weight: var(--w3);
}
