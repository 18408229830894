.navbar {
    padding-top: 24px;
    padding-bottom: 24px;
    background: inherit;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}

.navbar:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.6);
    filter: blur(3px);
    background: inherit;
}

.navbar .logo {
    position: absolute;
    width: 88px;
    height: 51px;

    font-family: Futura;
    line-height: 150%;
    /* identical to box height, or 51px */

    display: flex;
    align-items: center;

    color: #111111;
}

@media (max-width: 484px) {
    .navbar {
	padding-left: 8px;
	padding-right: 8px;
    }
}
